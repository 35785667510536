import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>Certificat mèdic oficial per oposicions</Title>
                <Text>
                    Acord de iOpos Acadèmia amb el centre mèdic Badamedic de Badalona.
                    <br />
                    <br />
                    Amb una important novetat enguany.
                    <br />
                    <br />
                    En totes les revisions es realitzarà el monitoratge de la freqüència cardíaca, i
                    obtenció d'un Electrocardiograma.
                    <br />
                    <br />
                    El dia de la revisió els nostres metges especialistes us formularan una sèrie de
                    preguntes per a conèixer l'estat de salut del pacient.
                    <br />
                    <br />
                    Posteriorment es realitzen una sèrie de proves i exploracions:
                    <br />
                    <br />
                    -IMC
                    <br />
                    <br />
                    -Capacitat pulmonar
                    <br />
                    <br />
                    -Freqüència cardíaca i presa de tensió arterial
                    <br />
                    <br />
                    -Exploració aparell locomotor bàsica
                    <br />
                    <br />
                    -Electrocardiograma en repòs
                    <br />
                    <br />
                    Per accedir a la revisió, s'ha de fer el pagament a través del link.
                    <br />
                    <br />
                    Un cop feta la compra, demanar cita al telèfon 618.86.41.79
                    <br />
                    <br />
                    El dia de la revisió caldrà aportar el DNI de la persona, l'escrit de les bases
                    i la fulla del certificat mèdic oficial de l'estanc.
                    <br />
                    <br />
                    Preu 29.99 euros i els següents dintre d'un termini de sis mesos a 5 euros.
                    <br />
                    <br />
                    Centre Badamedic ( Baldomer Solà num 80, Badalona)
                    <br />
                    <br />
                    Perquè a iOpos ens importa la teva salut!
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
